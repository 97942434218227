html, body {
  background: #000;
  margin: 0;
  height: 100%;
  padding: 0;
  overflow: hidden;
  font-family: "Roboto", Arial, sans-serif;
  letter-spacing: 0.05rem;
}

#container {
  padding-top: 56.25%;
  position: absolute;
  display: block;
  width: 80vw;
  max-width: 1194.75px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.monitor {
  position: absolute;
  width: 100%;
  height: 80%;
  max-height: 675px;
  top: 10%;
  cursor: crosshair;
}

.screen {
  width: 100%;
  height: 100%;
}

.text {
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  font-size: 2rem;
}

.title {
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.cam {
  bottom: 0;
  right: 0;
  transform: translateY(100%);
}

.startButton {
  top: 50%;
  left: 50%;
  font-size: 4rem;
  letter-spacing: 0.075em;
  transform: translate(-50%, -50%);
  display: none;
}

.rec {
  top: 0;
  right: 0;
  transform: translateY(-90%);
  color: darkred;
}

.social {
  position: absolute;
  bottom: 0;
  left: 0;
}

.social .icon {
  position: absolute;
  text-indent: -999px;
  width: 32px;
  height: 28px;
  margin-top: 2px;
  display: block;
  background: url("envelope.a6d4989a.svg") no-repeat center;
  background-size: contain;
}

.social .facebook {
  background-image: url("fb.8f0880b0.svg");
  left: 70px;
  height: 18px;
  margin-top: 7px;
}

.social .jack {
  background-image: url("twitter.b685158c.svg");
  left: 40px;
  height: 20px;
  margin-top: 6px;
}

@media only screen and (min-width: 1400px) {
  #container {
    padding-top: 800px;
  }
}

@media only screen and (max-height: 1000px) and (min-width: 800px) {
  #container {
    padding-top: 80vh;
    width: 124.44439vh;
  }
}

@-moz-document url-prefix() {
  .social {
    transform: translateY(76%);
  }
  .social .fb-like {
    transform: translateY(-12px);
  }
}

.is-hidden {
  visibility: hidden;
}
/*# sourceMappingURL=index.378c555c.css.map */
